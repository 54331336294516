@import '../../variables.scss';

.postpage-container {
    .title {
        font-weight: 700;
        text-transform: capitalize;
        margin: 2rem 0 0 0;
    }

    .author-info {
        display: flex;
        gap: 3px;
        margin: 8px 0 16px;
        font-size: 10pt;

        .dot {
            color: $flank-gray-bg;
            font-size: $base-font-size;
            font-weight: 700;

            &::after {
                content: '\b7';
            }
        }

        span {
            font-size: $base-font-size;
        }

        .bold {
            font-weight: 700;
        }
    }

    .post-image {
        width: 100%;
        border-radius: 6px;
    }
    .post-content {
        line-height: 1.5;

        img {
            max-width: 100%;
        }
    }
}
