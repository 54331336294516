@import '../../variables.scss';

.flank-popover {
    display: inline-block;

    .button {
        padding: 0.25em;
        background-color: transparent;
        border: none;
        font-size: 14px;
        color: #536471;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;

        &:hover {
            cursor: pointer;
        }
    }

    .flank-popover-content {
        position: fixed;
        top: 100%;
        left: 0;
        right: auto;
        border: 1px solid #536471;
        border-radius: $border-radius;
        padding: 0.5em 0;
        z-index: 1;
        box-shadow: 0 0.25em 0.25em rgba(0, 0, 0, 0.1);
        background-color: #fff;
        visibility: hidden;

        &.open {
            visibility: visible;
        }

        .action {
            display: flex;
            align-items: center;
            white-space: nowrap;
            padding: $small-padding $medium-padding;
            width: 100%;
            border: none;
            font-family: $font-family;
            font-size: 14px;
            background-color: transparent;
            text-decoration: none;
            color: #000;

            svg {
                margin-right: 0.75em;
                margin-left: -0.5em;
                color: $flank-green;
            }

            &:hover {
                background-color: rgba(0, 0, 0, 0.03);
                cursor: pointer;
            }
        }
    }
}

@media (max-width: $phone-breakpoint) {
}
