.start-button {
    align-items: center;
    justify-content: center;
    background-color: #00cd00;
    margin-left: 200px;
    margin-top: 40px;
    height: 51px;
    width: 272px;
    border-color: #00cd00;
    border-radius: 6px;
    color: white;
    font-weight: bold;
}
.industry-buttons {
    display: flex;
}

.industry-buttons button {
    margin-right: 10px;
    margin-bottom: 10px;
    background-color: white;
    height: 50px;
    min-width: 109px;
    max-width: 100%;
    border-radius: 6px;
    border: 1px solid #ccc;
    overflow-wrap: break-word;
    cursor: pointer;
    white-space: normal;
}

.industry-buttons button.selected {
    border: 1px solid black;
    background-color: #eee;
}
.sector-container {
    border: 1px solid #ccc;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 5px;
    overflow: scroll;
}
.industry-title {
    display: flex;
    margin-right: 20px;
}

@media screen and (max-width: 768px) {
    .start-button {
        margin-left: auto;
        margin-right: auto;
        margin-top: 20px;
        display: block;
    }
}
