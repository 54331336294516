@import '../../variables.scss';

.flank-button {
    display: inline-flex;
    padding: 0.5rem 1rem;
    font-size: $base-font-size;
    font-family: $font-family;
    color: $text-color;
    background-color: $input-background-color;
    background-clip: padding-box;
    border: 1px solid $input-border-color;
    border-radius: $border-radius;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none;
    cursor: pointer;
    outline: none;
    align-items: center;
    justify-content: center;

    &:hover {
        border-color: $flank-green;
    }

    &:active {
        border-color: $flank-green;
    }

    &:disabled {
        background-color: #ddd;
        color: #666;
        cursor: not-allowed;

        &:hover {
            border-color: #ddd;
        }
    }

    .spinner {
        height: 1.5em;
        width: 1.5em;
        margin-right: 0.5em;
    }

    &.block {
        display: flex;
        width: 100%;
    }

    &.action {
        border: 1px solid $flank-green-bg;
        background-color: $flank-green-bg;
        color: #fff;
        text-transform: uppercase;
        font-weight: bold;
        padding: 0.5rem 4rem;

        &:hover {
            background-color: $flank-green-bg-hover;
        }

        &:active {
            background-color: $flank-green-bg-active;
        }

        .spinner {
            fill: white;
        }

        &:disabled {
            background-color: desaturate($flank-green-bg, 70%);
            color: #ddd;

            .spinner {
                fill: #ddd;
            }
        }
        &.skinny-btn {
            padding: 0px;
            width: 100%;
        }
    }

    &.gray-btn {
        border: 1px solid $flank-gray-bg;
        background-color: $flank-gray-bg;
        color: #fff;
        font-weight: bold;
        padding: 0.4rem 0.6rem;

        &:hover {
            background-color: $flank-gray-bg-hover;
        }

        &:active {
            background-color: $flank-gray-bg-active;
        }

        .spinner {
            fill: white;
        }

        &:disabled {
            background-color: darken($flank-gray-bg, 10%);
            color: #ddd;

            .spinner {
                fill: #ddd;
            }
        }
        &.skinny-btn {
            padding: 0px;
            width: 100%;
        }
    }

    &.danger {
        border: 1px solid $danger-color;
        background-color: $danger-color;
        color: #fff;

        &:hover {
            background-color: $danger-color-light;
        }

        &:active {
            background-color: $danger-color-dark;
        }

        .spinner {
            fill: white;
        }

        &:disabled {
            background-color: desaturate($danger-color, 70%);
            color: #ddd;

            .spinner {
                fill: #ddd;
            }
        }
    }

    &.plain {
        border: none;

        &:disabled {
            background-color: desaturate($danger-color, 70%);
            color: #ddd;

            .spinner {
                fill: #ddd;
            }
        }

        &.danger {
            color: $danger-color;
            background: none;
            font-weight: bold;

            &:hover {
                color: $danger-color-light;
            }

            &:active {
                color: $danger-color-dark;
            }

            .spinner {
                fill: $danger-color;
            }
        }
    }

    .icon {
        line-height: 0;
        display: inline-block;
        margin-right: 0.5em;
        font-size: $base-font-size;

        &.icon-only {
            margin-right: 0;
            margin-right: 0.25em;
            font-size: x-large;
        }

        &.icon-only-small {
            margin-right: 0;
            margin-right: 0.25em;
            font-size: 12px;
        }
    }

    .icon-after {
        line-height: 0;
        display: inline-block;
        margin-left: 0.5rem;
        font-size: $base-font-size;
    }

    &.greenOutline {
        border: 1px solid $flank-green;
        border-radius: $border-radius;
        text-decoration: none;
        color: $flank-green-bg;

        &:hover {
            background-color: rgba($flank-green, 0.1);
        }
        &:active {
            background-color: rgba($flank-green, 0.3);
        }

        &.action {
            border: 1px solid transparent;
            background-color: $flank-green-bg;
            color: #fff;
            text-transform: uppercase;
            font-weight: bold;

            &:hover {
                background-color: $flank-green-bg-hover;
            }

            &:active {
                background-color: $flank-green-bg-active;
            }
        }
    }

    &.fwd-btn {
        padding: 0.75rem 1rem;
    }
}

.flank-button-chain {
    float: right;
    display: flex;
    align-items: center;

    .flank-button {
        border-radius: 0;

        &:first-child {
            border-top-left-radius: $border-radius;
            border-bottom-left-radius: $border-radius;
        }

        &:last-child {
            border-top-right-radius: $border-radius;
            border-bottom-right-radius: $border-radius;
        }
    }

    &.timescale {
        button:first-child {
            margin-left: 0;
        }

        .flank-button {
            border: none;
            border-bottom: 3px solid transparent;
            padding: 0 0.5rem 0.5rem 0.5rem;
            border-radius: 0 !important;
            font-size: $small-font-size;
            font-weight: 700;
            margin: 0 0.5rem;
            letter-spacing: -1px;

            &.active {
                border-bottom: 3px solid $flank-green;
                color: $flank-green;
            }

            &:hover {
                color: $flank-green;
            }
        }
    }
}

.skinny-btn {
    padding: 0;
    margin: 0;
}

.xButton {
    padding: 0px;
    margin: 0px;
    font-size: 50px;
}
.back-btn {
}

@media (max-width: $phone-breakpoint) {
    .flank-button-chain {
        &:not(.timescale) {
            flex-direction: column;
        }

        .flank-button {
            padding: 1rem;
            display: flex;
            align-items: center;
            width: 80%;

            &:first-child {
                border-radius: $border-radius 0 0 $border-radius;
            }

            &:last-child {
                border-radius: 0 $border-radius $border-radius 0;
            }
        }

        &.timescale {
            max-width: 75%;
        }
    }
}
