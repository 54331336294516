@import '../../variables.scss';

.flank-loading-modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-grow: 1;

    .spinner {
        width: 48px;
        height: 48px;
        fill: $flank-green;
        margin-bottom: 0.5em;
    }

    .snark {
        font-size: 0.8em;
        color: #888;
    }

    .message {
        margin-top: 1em;
        font-size: 1.2em;
    }

    &.block {
        height: 300px;
        position: relative;
        border: 1px solid #aaa;
        border-radius: $border-radius;
        background-color: rgba(0, 0, 0, 0.01);
    }
}

.flank-sidebar-wrapper {
    .flank-loading-modal {
        &.block {
            margin: 0 1em;
        }
    }
}
