@import '../../variables.scss';

.flank-sidebar-wrapper {
    .sidebar-item {
        width: 100%;

        button {
            cursor: pointer;
            transition: background-color ease-in-out 0.2s;

            &:hover {
                background-color: hsl(220, 10.7%, 89%, 0.3);
            }
        }

        .sidebar-item-title {
            padding: $small-padding $medium-padding;
            display: flex;
            align-items: center;
            width: 100%;
            background: transparent;
            border: 0;
            text-align: left;
            transition: all 0.2s;
            color: black;

            &.active {
                background-color: #eee;
            }
            .sidebar-item-chart {
                min-width: 36px;
                min-height: 36px;
            }

            .sidebar-item-icon {
                width: 36px;
                max-width: 40px;
                min-width: 40px;
                padding: 1rem 0.25rem;
                border-radius: $border-radius;
                flex: 1;

                &.company {
                    min-width: 52px;
                }

                // &.up {
                //     background-color: #E0F7E6;
                // }

                // &.down {
                //     background-color: #FBE7E5;
                // }

                .sidebar-item-chart {
                    border-radius: 50%;
                }

                img {
                    width: 48px;
                    height: 48px;
                    background-color: #f6f7f9;
                    border-radius: $border-radius;
                }
            }

            .sidebar-item-text {
                flex: 1;
                padding: 0.25rem 0.75rem 0.25rem 1.25rem;
                display: flex;
                flex-direction: column;
                gap: $small-gap;

                .sidebar-item-text-title {
                    white-space: normal;
                    font-size: 0.75rem;
                }

                .sidebar-item-text-subtitle {
                    font-size: 0.75rem;
                    color: #697179;
                }
                .movement-pill {
                    padding: 0;
                }
            }

            .sidebar-item-percentage {
                padding: 0.5em 1em;
            }

            .sidebar-item-arrow {
                width: 5em;
                text-align: center;
            }

            .spark-data {
                display: flex;
                align-items: center;
                justify-content: center;
                .spark-chart {
                    margin-right: 1rem;
                    width: 60px !important;
                    height: 25px !important;
                }
            }

            .spark-spinner {
                width: 24px;
                fill: $flank-green;
            }
        }

        .sidebar-item-subitems {
            .sidebar-item {
                .sidebar-item-title {
                    padding-left: 1.5rem;
                }
            }
        }

        &.open {
            & > .sidebar-item-title {
                .sidebar-item-arrow {
                    .icon {
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }

    .breadcrumbs {
        padding: $small-padding;

        .breadcrumb-btn {
            font-size: $small-font-size;
            font-weight: 700;
            color: $text-color;
            letter-spacing: -1px;
            border: none;
            background: none;
            cursor: pointer;
            appearance: none;
            outline: none;

            &:hover {
                color: $flank-green;
            }
        }
        .breadcrumb-btn:nth-child(1) {
            margin-left: 0.5rem;
        }

        .breadcrumb-separator {
            font-size: 0.75rem;
            margin: 0 0.25rem 0 -0.25rem;
        }
    }
}

@media (max-width: $phone-breakpoint) {
}
