@import '../../../../variables.scss';

.financials-container {
    .financials {
        width: 100%;
        border-top: 1px $gray-color solid;
        border-bottom: 1px $gray-color solid;
        padding: 0.5rem 1rem 1rem 1rem;
    }

    .financials th,
    .financials td {
        text-align: left;
        height: 3rem;
        display: flex;
        align-items: center;
        padding: 0.5rem 0;
    }

    .financials tr {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .time {
        color: $flank-gray-text;
        font-weight: 500;
    }

    .metrics {
        flex: 8;
        font-weight: 700;
    }

    .table-values {
        flex: 1;
        div {
            width: 100%;
            text-align: right;
            padding-right: 2rem;
        }
    }
}
