@import '../../variables.scss';

.search-box {
    position: relative;
    display: flex;
    justify-content: right;

    .search-box-control-container {
        height: 100%;
        outline: none;
        background: transparent;
        opacity: 0;
        transition: min-width 0.4s cubic-bezier(0.68, 0.55, 0.265, 1), max-width 0.4s cubic-bezier(0.68, 0.55, 0.265, 1),
            opacity 0.4s cubic-bezier(0.68, 0.55, 0.265, 1), padding-left 0.4s cubic-bezier(0.68, 0.55, 0.265, 1),
            border-left 0.4s cubic-bezier(0.68, 0.55, 0.265, 1), margin-right 0.4s cubic-bezier(0.68, 0.55, 0.265, 1),
            padding-right 0.4s cubic-bezier(0.68, 0.55, 0.265, 1);
        position: absolute;
        right: 100%;
        top: 0;
        min-width: 0;
        max-width: 0;
        border-radius: $border-radius;

        .search-control {
            padding: 0.5rem 0rem;
            font-size: $base-font-size;
            font-family: $font-family;
            line-height: 1.5;
            color: $text-color;
            background-clip: padding-box;
            border: none;
            border-radius: $border-radius;
            border: 1px solid #fff;
            width: 100%;
            outline: none;
            height: 100%;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            right: 0;
            bottom: 0;
        }

        &.open {
            opacity: 1;
            min-width: 300px;
            max-width: 100%;
            border-right: 1px solid $flank-green;
            margin-right: 0.75rem;

            .search-control {
                padding: 0.5rem 0.75rem;
                border: none;
            }
        }
    }

    .search-button {
        background-color: transparent;
        border: none;
        color: #fff;
        padding: 0.5rem;
        margin: 0;
        transition: padding 1s cubic-bezier(0.68, 0.55, 0.265, 1),
            background-color 0.4s cubic-bezier(0.68, 0.55, 0.265, 1);
        border-radius: 0 $border-radius $border-radius 0;

        &:hover {
            color: $flank-green;
            cursor: pointer;
        }

        &.open {
            background-color: transparent;
            color: $flank-green;
            padding: 0.5rem;
            margin: 0;
            border: none;
            .magnifying-glass {
                fill: $flank-green;
            }
        }
    }
}

@media only screen and (max-width: $phone-breakpoint) {
    .search-box {
        .search-box-control-container.open {
            &.open {
                min-width: calc(100vw - 8rem);
                z-index: 11;
            }
        }
    }
}
