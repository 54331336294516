@import '../../variables.scss';

.flank-alert {
    padding: 1rem;
    border: 1px solid #ccc;
    margin: 1rem 0;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    border-left: 6px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    display: flex;
    align-items: center;
    width: 100%;

    .wrapper {
        display: flex;
        flex-grow: 1;
        align-items: flex-start;

        .icon {
            margin-right: 0.5em;
            margin-top: 0.1em;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 1.25em;
        }

        .alert-content {
            flex-grow: 1;
            text-align: left;

            .alert-header {
                font-size: 1.25rem;
                font-weight: bold;
                margin-bottom: 0.5rem;
            }

            .alert-body {
                font-size: 1rem;

                a,
                a.link {
                    color: #000;
                    font-weight: bold;
                    text-decoration: underline;

                    &:hover {
                        color: #444;
                    }
                }
            }
        }
    }

    &.with-header {
        .icon {
            margin-top: 0.25em;
            margin-right: 0.75em;
        }
    }

    &.danger {
        border-left-color: $danger-color;
        color: $danger-color-dark;

        .icon {
            color: $danger-color-dark;
        }

        .close {
            &:hover button {
                color: $danger-color-dark;
            }
        }

        .wrapper {
            .alert-content {
                .alert-body {
                    a,
                    a.link {
                        color: $danger-color-dark;

                        &:hover {
                            color: $danger-color;
                        }
                    }
                }
            }
        }
    }

    &.success {
        border-left-color: $success-color;
        color: $success-color-dark;

        .icon {
            color: $success-color-dark;
        }

        .close {
            &:hover button {
                color: $success-color-dark;
            }
        }

        .wrapper {
            .alert-content {
                .alert-body {
                    a,
                    a.link {
                        color: $success-color-dark;

                        &:hover {
                            color: $success-color;
                        }
                    }
                }
            }
        }
    }

    &.warning {
        border-left-color: $warning-color;
        color: $warning-color-dark;

        .icon {
            color: $warning-color-dark;
        }

        .close {
            &:hover button {
                color: $warning-color-dark;
            }
        }

        .wrapper {
            .alert-content {
                .alert-body {
                    a,
                    a.link {
                        color: $warning-color-dark;

                        &:hover {
                            color: $warning-color;
                        }
                    }
                }
            }
        }
    }

    &.info {
        border-left-color: $info-color;
        color: $info-color-dark;

        .icon {
            color: $info-color-dark;
        }

        .close {
            &:hover button {
                color: $info-color-dark;
            }
        }

        .wrapper {
            .alert-content {
                .alert-body {
                    a,
                    a.link {
                        color: $info-color-dark;

                        &:hover {
                            color: $info-color;
                        }
                    }
                }
            }
        }
    }

    &.spinner {
        border-left-color: $flank-green;

        .icon {
            display: flex;
            align-items: center;
            margin-top: 0;
            .spinner {
                display: flex;
                align-items: center;
                height: 1.5em;
                width: 1.5em;

                div {
                    width: 100%;
                }
            }
        }
    }

    &.no-style {
        margin: 0;
        border-top: none;
        border-right: none;
        border-bottom: none;
        box-shadow: none;
    }

    &.block {
        .wrapper {
            flex-direction: column;
            gap: $medium-gap;
            padding-bottom: $small-padding;

            .icon {
                font-size: 1.75rem;
            }

            .alert-content {
                margin-left: 0.5rem;
            }
        }
    }
}
