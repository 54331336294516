@import '../../variables.scss';

.flank-expandable-comparison-section {
    margin-bottom: 5em;
    padding-bottom: 5em;
    border-bottom: 10px solid #eee;

    &:last-child {
        border-bottom: none;
        margin-bottom: 0;
        padding-bottom: 0;
    }

    .flank-expandable-comparison-section {
        margin-bottom: 2em;
        border-bottom: 0;
        padding-bottom: 0;

        &:last-child {
            border-bottom: none;
            margin-bottom: 0;
            padding-bottom: 0;
        }

        .section-header {
            top: 148px;
            z-index: 3;
        }

        .flank-expandable-comparison-section {
            .section-header {
                top: 211px;
                z-index: 1;
            }
        }
    }

    .section-header {
        display: flex;
        align-items: center;
        margin-bottom: 1em;
        background-color: #fff;
        border-radius: $border-radius;
        z-index: 5;

        .icon {
            padding: 2em 1.5em;
            border-radius: $border-radius;
            margin-right: 1em;
        }

        .title-wrapper {
            display: flex;
            flex-direction: column;
            flex: 1;

            .title {
                font-size: 16pt;
                display: flex;
                align-items: flex-start;

                .separator {
                    margin: 0 0.5em;
                }

                button {
                    font-size: 16pt;
                    border: none;
                    margin: 0;
                    padding: 0;
                    background-color: transparent;
                    font-weight: normal;
                    font-family: $font-family;
                    color: $text-color;
                    transition: border-bottom 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
                    appearance: none;
                    cursor: pointer;
                    outline: none;

                    &.active {
                        border-bottom: 4px solid $flank-green;
                        padding-bottom: 0.25rem;
                        margin-bottom: 0.25rem;
                    }

                    &:hover {
                        cursor: pointer;
                        color: $flank-green;

                        &::after {
                            color: #000;
                        }
                    }
                }
            }

            .subtitle {
                color: #697179;
            }
        }

        .expand-button {
            button {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 25px;
                height: 25px;
                padding: 0;
                margin: 0;
                border-radius: 50%;
                background-color: #fff;
                border: 1px solid #ccc;
                cursor: pointer;
                transition: transform 0.1s ease-in-out;
            }
        }
    }
    &.open {
        & > .section-header {
            .expand-button {
                button {
                    transform: rotate(180deg);
                }
            }
        }
    }
}

@media (max-width: $phone-breakpoint) {
}
