@import '../../variables.scss';

.flank-error-page {
    display: flex;
    width: 100vw;
    height: 100vh;
    align-items: flex-start;
    padding-top: 150px;
    justify-content: center;

    .flank-error-page-content {
        border-radius: $border-radius;
        padding: $large-padding;
        background: $off-white-background-color;
        display: flex;
        gap: $large-gap;
        flex-direction: column;

        h1 {
            @include title-font;
        }

        .return {
            display: flex;
            gap: $small-gap;
            .code {
                text-transform: uppercase;
                font-weight: 700;
            }
        }
    }
}

@media only screen and (max-width: $phone-breakpoint) {
    .flank-error-page {
        padding: 100px 10px;
        justify-content: center;

        .flank-error-page-content {
            width: 100%;
            padding: $medium-padding;
            gap: $medium-gap;
        }
    }
}
