@import '../../variables.scss';

.company-links {
    display: flex;
    overflow-x: auto;
    align-items: flex-end;
    justify-content: space-between;
    gap: 1rem;
    border-bottom: 1px solid #e0e2e6;
    padding-bottom: 1rem;
    margin-bottom: 1rem;

    .flank-link {
        font-size: $small-font-size;
        letter-spacing: -0.1px;
        padding: 0 0 0.5rem 0;
        margin: 0 0 0rem 0;
    }
}
.sidebar-company-nav-links {
}

.flank-company-page {
    display: flex;
    flex-direction: column;
}
