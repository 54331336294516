@import '../../variables.scss';

.flank-table-wrapper {
    position: relative;

    .shadow {
        box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.5);
        position: absolute;
        top: 0;
        bottom: 0;
        z-index: 1;

        &.shadow-left {
            left: 0;
        }

        &.shadow-right {
            right: 0;
        }
    }

    .flank-table-actions {
        margin-bottom: 1em;
        display: flex;
        align-items: center;
    }

    .flank-table-container {
        width: 100%;
        display: block;
        overflow-x: auto;
        padding-bottom: 1em;
        position: relative;

        .flank-table {
            width: 100%;
            border-collapse: collapse;
            border-spacing: 0;
            color: #000;
            border-radius: $border-radius;
            overflow: hidden;

            .flank-table-header {
                .flank-table-row {
                    border-bottom: 1px solid #ccc;

                    .flank-table-cell {
                        padding: 1em;
                        text-align: left;
                        white-space: nowrap;
                        font-family: $font-family;
                        font-size: $base-font-size;
                        font-weight: bold;

                        .cell-button {
                            display: flex;
                            width: 100%;
                            text-align: left;
                            padding: 1em;
                            margin: -1em;
                            background-color: transparent;
                            border: none;
                            cursor: pointer;
                            font-family: $font-family;
                            font-size: $base-font-size;
                            font-weight: bold;
                            white-space: nowrap;
                            justify-content: space-between;
                            align-items: center;
                            color: black;

                            &:active {
                                background-color: rgba(0, 0, 0, 0.03);
                            }
                        }
                    }

                    .flank-table-row-actions {
                        width: 1%;
                    }
                }
            }

            .flank-table-body {
                .flank-table-row {
                    .flank-table-cell,
                    .flank-table-row-actions {
                        width: 1%;
                        padding: 1em;
                        text-align: left;
                        border-bottom: 1px solid #ccc;
                        transition: background-color 0.1s ease-in-out;
                        white-space: nowrap;
                    }

                    .flank-table-row-actions {
                        width: 1%;
                        text-align: right;
                    }

                    &:last-child {
                        .flank-table-cell,
                        .flank-table-row-actions {
                            border-bottom: none;
                        }
                    }

                    &:hover {
                        .flank-table-cell,
                        .flank-table-row-actions {
                            background-color: #f5f5f5;
                        }
                    }
                }
            }

            &.push-left {
                .flank-table-body {
                    .flank-table-row {
                        .flank-table-cell,
                        .flank-table-row-actions {
                            &:last-child {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }

    &.with-actions {
        .flank-table-container {
            .flank-table {
                .flank-table-header {
                    .flank-table-row {
                        // border: 1px solid #ccc;
                        // border-top: none;
                    }
                }
            }
        }
    }

    .flank-table-pagination {
        padding: 1em;

        .flank-table-pagination-controls {
            display: flex;
            align-items: center;
        }

        .flank-table-pagination-info {
            margin-top: 1em;
        }

        .pagination-text {
            margin: 0 0.25em;
            display: inline-block;

            // &:first-of-type {
            //     margin-left: 1em;
            // }

            // &:only-child {
            //     margin-left: 0;
            // }
        }

        .pagination-control {
            border: 1px solid #aaa;
            background-color: #fff;
            border-radius: $border-radius;
            padding: 0.5em 0.5em;
            transition: background-color 0.1s ease-in-out, border-color 0.1s ease-in-out;
            color: #000;
            appearance: none;

            &:active,
            &:focus {
                background-color: #fff;
                transition: background-color 0s ease-in-out, border-color 0s ease-in-out;
            }

            &:hover:not(:focus) {
                border: 1px solid $flank-green;
                background-color: rgba(0, 0, 0, 0.03);
            }

            &.buttons {
                margin: 0 0.25em;
                padding: 0.5em 0.75em;

                &:first-child {
                    margin-left: 0;
                }

                &:disabled {
                    background-color: #ddd;
                    border: 1px solid #ddd;
                    cursor: not-allowed;

                    &:hover {
                        border: 1px solid #ddd;
                    }
                }
            }
        }

        .separator {
            margin: 0 0.5em;
            display: inline-block;
            border-top: 1px solid #ccc;
            width: 20px;
        }
    }
}

@media (max-width: $phone-breakpoint) {
    .flank-table-wrapper {
        .flank-table-container {
            .flank-table {
                .flank-table-header {
                    .flank-table-row {
                        .omit-on-mobile {
                            display: none;
                        }
                    }
                }

                .flank-table-body {
                    .flank-table-row {
                        .omit-on-mobile {
                            display: none;
                        }
                    }
                }
            }
        }

        .flank-table-pagination {
            .pagination-control {
                padding: 1em;

                &.buttons {
                    padding: 1em 1.25em;
                }
            }
        }
    }
}
