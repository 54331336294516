@import '../../../../variables.scss';

.company-strategy-wrapper {
    padding: 1rem;

    .company-page-header {
        margin-bottom: 1rem;
    }

    .press-release-wrapper {
        .press-release {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 14px;
            font-weight: 500;
            padding: 1rem;
            margin: -1rem;

            &:hover {
                background-color: $light-gray-color;
                border-radius: $medium-border-radius;
            }

            .company-name {
                color: $flank-green;
                font-weight: 600;
            }

            .press-word {
                font-weight: 600;
            }

            .press-release-date {
                font-size: 12px;
                font-weight: normal;
                padding-bottom: 0.5rem;
            }

            .press-release-title {
                padding: 0.75rem 0;
                font-size: 16px;
                font-weight: 600;
            }
        }
    }

    .see-more-wrapper {
        padding: 2rem 0;
        text-align: right;
        .see-more {
        }
    }

    .content-section {
        padding-top: 1rem;
        margin-top: 2rem;
    }
}
