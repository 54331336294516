@import '../../variables.scss';

.spacer-header {
    color: #7c7c7c;
    margin: 0;
    font-weight: 700;
    font-size: small;
}

.flank-spacer {
    display: block;
    border: none;
    border-bottom: 1px solid #e0e2e6;
    height: 1px;
    width: 100%;

    &.skinny {
        margin: 0;
        padding: 0;
    }
}
