@import '../../../../../variables.scss';

.company-links-wrapper {
    .company-link {
        display: flex;
        flex-direction: row;
        align-items: center;
        //gap between the company-links
        padding: 1rem 0;
        .link-icon {
            padding: 1em;
            background-color: #f6f7f9;
            border-radius: $border-radius;
            margin-right: 1.5em;
            display: flex;
            align-items: center;
            justify-content: center;

            svg,
            img {
                filter: grayscale(100%);
                width: 30px;
                height: 30px;
            }
        }
    }
}
