@import '../../variables.scss';

.badge-carousel {
    display: flex;
    position: relative;
    margin-top: 1.5rem;

    &-content {
        justify-content: flex-start;
        display: flex;
        align-items: center;
        gap: 1rem;
        overflow-x: auto;
        /* Hide scrollbar for IE, Edge and Firefox */
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */

        /* Hide scrollbar for Chrome, Safari and Opera */
        &::-webkit-scrollbar {
            display: none;
        }
        .badge-container.bigbox {
            margin-right: 0.5rem; // Adjust the value as needed
        }
    }

    .carousel-btn {
        z-index: 50;
        border-radius: 70px;
        background-color: white;
        border: 0.5px solid #eff3f4;
        width: 1.5rem;
        height: 1.5rem;
        position: absolute;
        cursor: pointer;
        top: 50%;
        transform: translateY(-50%);
        transition: all 0.2 ease-in-out;

        &:hover {
            background-color: #eee;
        }
        &:active {
            background-color: #ddd;
        }
    }
    .carousel-btn-left {
        left: -0.75rem;
    }
    .carousel-btn-right {
        right: -0.75rem;
    }
}
