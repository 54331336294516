@import '../../variables.scss';

.flank-footer {
    background-color: #000;
    color: #fff;
    align-items: center;
    justify-content: center;

    .inner {
        margin: 0 auto;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        .column {
            width: 100%;
            padding-left: 2em;
            padding-right: 1.5em;
            padding-top: 0.8em;
            padding-bottom: 0.8em;

            .footer-logo {
                fill: #fff;
                width: 80px;
                height: 30px;
                margin-top: 20px;

                &:hover {
                    .leaf {
                        fill: $flank-green;
                    }
                }
            }

            .column-header {
                font-size: 10pt;
                font-weight: bold;
                text-transform: uppercase;
                margin-bottom: 1em;
                color: #8f9193;
            }

            .navigation {
                list-style: none;
                margin: 0;
                padding: 0;
                display: flex;
                justify-content: center;
                align-items: center;

                li {
                    display: flex;
                    padding-top: 15px;
                    padding-left: 18px;
                    padding-right: 18px;
                    a {
                        display: flex;
                        padding: 0.25em;
                    }
                }
            }

            &.icons {
                display: flex;
                align-items: center;
                justify-content: flex-end;

                a {
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    color: #fff;
                    background-color: #222326;
                    border-radius: 20em;
                    font-size: 16pt;
                    width: 40px;
                    height: 40px;
                    margin-left: 0.5em;
                    text-decoration: none;
                    margin-top: 8px;
                    .social-icon {
                        height: 18px;
                        width: 18px;
                    }

                    &:hover {
                        background-color: $flank-green-bg-active;
                    }
                }
            }
        }
    }
}

@media (max-width: $phone-breakpoint) {
    .flank-footer {
        .inner {
            width: 100%;
            display: grid;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .column {
                width: 100%;
                .navigation {
                    width: 100%;
                    display: block;
                    li {
                        a {
                            font-size: 12pt;
                            padding: 0.5em 0;
                            width: 100%;
                        }
                    }
                }

                &.icons {
                    justify-content: center;
                }
            }
        }
    }
}
