@import '../../variables.scss';

.profile-header-container {
    margin: 32px 0;
    display: flex;

    .profile-pic-container {
        width: 136px;
        height: 136px;
        border-radius: 136px;
        background-color: $text-background-color;
        display: flex;
        align-items: center;
        justify-content: center;
        .initials {
            font-size: 64pt;
            text-transform: uppercase;
        }
    }

    .profile-data {
        flex: 1;
        margin-left: 8px;
        display: flex;
        flex-direction: column;
        row-gap: 8px;

        .name-container {
            display: flex;
            flex-direction: column;
            .name {
                font-size: 24px;
                font-weight: 700;
            }
            .username {
                color: $gray-text-color;
                font-size: 16px;
            }
        }

        .bio {
            font-size: 16px;
        }
        .flex-block {
            display: flex;
            align-items: center;
            column-gap: 8px;
            .date {
                color: $gray-text-color;
                font-size: 16px;
            }
            .badge {
                font-size: 16px;
            }
        }
    }
    .activity-icon {
        font-size: 20px;
        border: none;
        cursor: pointer;
    }
}
