@import '../../../../variables.scss';

.shake {
    animation: shake 0.3s linear;
}

.error {
    color: red;
    font-size: 0.9rem;
    margin-top: 0.5rem;

    &.neg-margin {
        margin-top: -1.75rem;
    }
}

.valuation-text {
    font-size: 0.9rem;
    color: $flank-gray-text;
    margin-top: 0.5rem;
}

@keyframes shake {
    0% {
        margin-left: 0rem;
    }
    25% {
        margin-left: 0.5rem;
    }
    50% {
        margin-left: 0rem;
    }
    75% {
        margin-left: -0.5rem;
    }
    100% {
        margin-left: 0rem;
    }
}
