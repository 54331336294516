@import '../../../../variables.scss';

.reference-selector {
    position: relative;

    .reference-select-button {
        color: $flank-green;
        font-size: $base-font-size;
        font-weight: 600;
        transition: color $transition-speed-fast $transition-shape;
        text-transform: lowercase;

        &:hover {
            color: $flank-green-bg-hover;
        }
    }
}

.popup-box {
    padding: 10px;
    border-radius: 4px;
}

// Styles specific to the contents of the popup
.popup-header {
    @include title-font;
}

.button-group {
    display: flex;
    justify-content: flex-end; // Align the button group to the right
    gap: $medium-gap;
}

.x-button {
    position: absolute;
    top: $small-margin;
    right: $small-margin;
}
