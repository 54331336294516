@import '../../variables.scss';

.flank-block-warning {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0em 1em;

    .flank-block-warning-icon {
        padding: 1em;
        background-color: #f6f7f9;
        border-radius: $border-radius;
        margin-right: 1.5em;
        display: flex;
        align-items: center;
        justify-content: center;

        svg,
        img {
            filter: grayscale(100%);
            width: 30px;
            height: 30px;
        }
    }

    .flank-block-warning-text {
        font-size: 1.2em;
    }

    .flank-block-warning-subtext {
        color: #697179;
    }
}

@media (max-width: $phone-breakpoint) {
}
