@import '../../variables.scss';

.text-tile-wrapper {
    &:first-child {
        margin-top: -1rem;
    }

    .section-tile {
        display: flex;
        margin-bottom: 1em;
        position: sticky;
        top: 50px;
        background-color: #fff;
        border-radius: $border-radius;
        z-index: 1;
        align-items: center;

        .icon-tile {
            padding: 2em 1.5em;
            border-radius: $border-radius;
            margin-right: 1em;
        }

        .link {
            padding: 2em 0em 2em 0.5em;
        }

        .text-wrapper {
            display: flex;
            flex-direction: column;
            flex: 1;
            align-items: flex-start;

            .title {
                font-size: 16pt;
                display: flex;
                align-items: flex-start;
            }

            .subtitle {
                color: #697179;
            }
        }
    }
}
