@import '../../variables.scss';

.flank-benefit-wrapper {
    background-color: $flank-green-bg-light;
    padding: 4em 0;

    // & * { border: 1px solid red; }

    &.dark {
        color: #fff;
        background-color: $flank-green-bg-dark;
    }

    .inner {
        margin: 0 auto;
        max-width: $desktop-breakpoint;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .content {
            padding: 2em;

            .tag {
                text-transform: uppercase;
                font-size: 12pt;
                margin-bottom: 1.25em;
                font-weight: bold;
            }

            .title {
                font-weight: bold;
                font-size: 32pt;
                margin-bottom: 0.5em;
                line-height: 32pt;
            }

            .clinch {
                font-size: 24pt;
                margin-bottom: 2em;
            }

            .link {
            }
        }

        .image {
            .wrapper {
                overflow: hidden;
                position: relative;
                height: 350px;
                width: 600px;

                img {
                    border-radius: $border-radius;
                    max-height: 100%;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }
    }
}

@media (max-width: $phone-breakpoint) {
    .flank-benefit-wrapper {
        .inner {
            display: flex;
            flex-direction: column;
            width: 100%;

            .content {
                padding: 1em;
                width: 100%;

                .title {
                    font-size: 24pt;
                }

                .clinch {
                    font-size: 16pt;
                }

                .link {
                    a {
                        white-space: nowrap;
                        display: block;
                        width: 100%;
                        text-align: center;
                    }
                }
            }
            .image {
                padding: 1em;
                width: 100%;

                .wrapper {
                    width: 100%;
                }
            }
        }
    }
}
