@import '../../variables.scss';

.barchart-table-wrapper {
    margin-left: -$medium-padding;
    margin-right: -$medium-padding;

    .barchart-container {
        min-height: 400px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }
}

.flank-comparison {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
    margin-bottom: 2rem;
    padding-top: 0.5rem;

    .header {
        color: $flank-gray-text;
        font-weight: $standard-font-weight;
        font-size: $small-font-size;
        letter-spacing: $tight-font-spacing;
    }

    .cumulative-text {
        color: $text-color;
        font-size: $regular-font-size;
        padding-right: 0.25rem;
    }

    .bar-table td:first-child,
    .bar-table th:first-child {
        width: 10%;
    }

    .bar-table td:last-child,
    .bar-table th:last-child {
        width: 5%;
    }

    .bar-table td:not(:first-child):not(:last-child),
    .bar-table th:not(:first-child):not(:last-child) {
        width: 17%;
    }

    .bar-table {
        display: table;
        width: 100%;
        border-collapse: collapse;
        border-top: none;
        padding-bottom: 1rem;

        tr {
            td {
                padding: 0.5rem;
                text-align: center;

                .change {
                    font-style: italic;
                    font-size: $small-font-size;
                }

                .change.negative {
                    color: $flank-negative-text;
                }

                .change.positive {
                    color: $flank-positive-text;
                }

                &.icon {
                    vertical-align: top;

                    .icon-wrapper {
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        .color-bar {
                            border: 2px solid #ccc;
                            border-radius: $border-radius;
                            height: 1.4rem;
                            margin-right: 0.2rem;
                        }
                        .company-icon {
                            max-width: 24px;
                        }

                        img {
                            max-height: $max-chart-image-size;
                        }
                    }
                }

                &.remove {
                    padding: 0;
                    width: 1rem;

                    .remove-button {
                        margin-top: 3px;
                        font-size: 16px;
                        max-height: 16px;
                        border: none;
                        background-color: transparent;
                        color: #000;
                        transition: color ease-in-out 0.1s;
                        padding: 0;

                        &:hover {
                            cursor: pointer;
                            color: $flank-green;
                        }
                    }
                }
            }

            &.cumulative-change-row {
                border-bottom: 1px $gray-color solid;
                td {
                    text-align: end;
                }
            }

            &.cumulative-change-row:not(:last-child) {
                border-bottom: 1px solid #dadada;
            }

            &:not(.cumulative-change-row):not(:first-child) {
                td {
                    padding-top: 1rem;
                }
            }
        }
    }
}

.comparison-actions {
    padding: 1em;

    .comparison-action-button {
        background-color: #f6f7f9;
        color: #000;
        padding: 0.5em;
        border: none;
        transition: background-color ease-in-out 0.1s;
        line-height: 1px;

        &:hover {
            cursor: pointer;
            background-color: darken(#f6f7f9, 5%);
        }

        &:not(:last-child) {
            margin-right: 1em;
        }

        &.active {
            background-color: $flank-green;
            color: #fff;
        }
    }
}

.comparison-action-controls {
    padding: 0 1em;

    .comparison-filter-group {
        margin-bottom: 1em;

        .comparison-filter-group-label {
            font-size: 15pt;
        }

        .comparison-filter-group-controls {
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;

            label {
                display: inline-block;
                margin-right: 1em;
                margin-top: 1em;
                user-select: none;

                input {
                    opacity: 0;
                    width: 0;
                    height: 0;
                    margin-right: -5px;
                }

                span {
                    display: inline-block;
                    border-bottom: 2px solid transparent;
                    transition: border-color ease-in-out 0.1s;
                }

                input:checked + span {
                    border-bottom: 2px solid $flank-green;
                }

                &:hover {
                    span {
                        cursor: pointer;
                        border-bottom: 2px solid #ccc;
                    }
                }
            }
        }
    }
}

//bubble chart
.flank-comparison {
    .flank-comparison-table {
        border-collapse: collapse;
        height: 4rem;

        tr.bubble {
            td {
                border-bottom: 1px solid $gray-color;
                padding: 0.5rem 0 0.5rem 0.5rem;
                border-collapse: collapse;
                border-spacing: 0;

                &.icon {
                    max-width: 1rem;
                    vertical-align: center;

                    .icon-wrapper {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;

                        .color-bar {
                            border: 2px solid #ccc;
                            border-radius: $border-radius;
                            height: 25px;
                            margin-right: 0.25rem;
                        }

                        img {
                            max-height: 25px;
                        }
                    }
                }

                &.change {
                    padding: 0;
                    text-align: right;
                }

                &.remove {
                    padding: 0;
                    width: 1rem;

                    .remove-button {
                        margin-top: 3px;
                        font-size: 16px;
                        max-height: 16px;
                        border: none;
                        background-color: transparent;
                        color: #000;
                        transition: color ease-in-out 0.1s;
                        padding: 0;

                        &:hover {
                            cursor: pointer;
                            color: $flank-green;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $phone-breakpoint) {
    .flank-comparison-table {
        .value,
        .change,
        .header,
        .cumulative-text {
            font-size: $mobile-paragaph-font-size;
        }
    }

    .flank-comparison .bar-table td.icon .icon-wrapper {
        img {
            max-height: $max-chart-image-size-mobile;

            .color-bar {
                height: $max-chart-image-size-mobile;
            }
        }
    }
}
