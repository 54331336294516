@import '../../variables.scss';

.flank-login-form {
    margin-bottom: 1rem;
}

.instruction {
    margin-bottom: 1rem;
}

.new-account-instruction {
    margin: 1rem 0;
    text-align: center;
}

@media (max-width: $phone-breakpoint) {
    .flank-login {
        padding-top: 2em;
        width: 100%;
    }
}
