@import '../../variables.scss';

.movement-pill {
    position: relative;
    padding: 0.5rem 0.5rem;
    border-radius: $border-radius;
    display: inline-flex;
    align-items: center;
    transition: background-color ease-in-out 0.2s, color ease-in-out 0.2s;
    max-width: 6rem;
    letter-spacing: -0.01rem;

    .direction-arrow {
        transition: transform ease-in-out 0.2s;
        margin-right: 0rem;
        min-width: 1rem;
    }

    &.down {
        color: #a50f0d;
        .direction-arrow {
            transform: rotate(180deg);
        }
    }

    &.up {
        color: #1f793c;
    }

    &.no-change {
        background-color: #f6f7f9;
        padding-right: 1rem;
        padding-left: 1rem;
    }

    &.border {
        min-width: 70px;
        &.up {
            background-color: $pill-background-positive;
        }
        &.down {
            background-color: $pill-background-negative;
        }
    }
}

.tooltip {
    visibility: hidden;
    background-color: #000;
    color: #fff;
    text-align: center;
    padding: 0.5rem 0.5rem;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    top: 125%;
    opacity: 0;
    transition: opacity 0.3s;
}

.movement-pill:hover .tooltip {
    visibility: visible;
    opacity: 1;
}

@media (max-width: $phone-breakpoint) {
    .movement-pill {
        font-size: $mobile-paragaph-font-size;
    }
}
