@import '../../variables.scss';

.input-icon-wrapper {
    position: relative;
    display: inline-block; /* or flex if you prefer */
}

.search-icon {
    position: absolute;
    left: 30px;
    top: 35%;
    transform: translateY(-50%);
    z-index: 10;
    height: 10px;
    background-color: #f0f3f4;
}

.search-bar {
    background-color: #f0f3f4;
    border-radius: 60px;
    width: 600px;
    height: 43px;
    align-items: center;
    justify-content: center;
    color: black;
    margin-left: 20px;
    margin-bottom: 0px;
    font-size: 19px;
    text-align: center;
}

.collections {
    width: 100%;
    margin-top: 1.5rem;
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 1.5rem;
}

.homepage-title {
    font-size: 24px;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0;
}

section {
    .section-header {
        color: $gray-text-color;
        font-size: 12px;
        font-weight: 700;
        text-transform: uppercase;
        margin: 0;
    }
}

.my-companies {
    margin-top: 60px;

    .company-row-wrapper {
        .company-row {
            margin: 22px 0 16px 0;
            display: flex;

            .company-container {
                flex: 1;
            }
        }
    }
}

.watchlist {
    margin-top: 32px;
    .watchlist-row-wrapper {
        .watchlist-row {
            margin: 16px 0;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .company {
                display: flex;
                align-items: center;
                column-gap: 0.5rem;
                .logo {
                    width: 32px;
                    height: 32px;
                    border-radius: $border-radius;
                    object-fit: cover;
                }

                .name {
                    font-size: $base-font-size;
                }
            }
        }
    }
}

.in-progress {
    margin-top: 32px;
    .in-progress-row-wrapper {
        .in-progress-row {
            margin: 16px 0;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .company {
                display: flex;
                align-items: center;
                column-gap: 7px;
                .logo {
                    width: 32px;
                    height: 32px;
                    border-radius: $border-radius;
                    object-fit: cover;
                }

                .name {
                    font-size: $base-font-size;
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .search-bar {
        width: 90%;
        font-size: 16px;
        height: 35px;
        margin-left: 10px;
    }

    .search-icon {
        left: 15px;
        height: 8px;
    }
}

@media (max-width: 480px) {
    .search-bar {
        width: 100%;
        font-size: 14px;
        margin-left: 0;
    }

    .search-icon {
        left: 10px;
    }
}
