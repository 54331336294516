@import '../../variables.scss';

.badge-container {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: 0.75rem 1.5rem;
    border-radius: $border-radius;
    border: 1px solid $gray-color;
    cursor: pointer;
    transition: all ease 0.2s;

    &:hover {
        background-color: #eee;
    }

    &.selected {
        border-color: black;
        background-color: #eee;
    }

    .icon {
        display: flex;
        align-items: bottom;
    }

    &.bigbox {
        flex-direction: column;
        align-items: center;
        width: 6rem;
        min-height: 8rem;
        flex-shrink: 0;
        gap: 0px;
        max-height: 100px;

        &.no-icon {
            padding: 44px 30px;
        }

        .icon {
            font-size: 16pt;
            display: block;
        }

        span {
            max-width: 100px;
            text-align: center;
            white-space: normal;
        }
    }

    &.icon-last {
        flex-direction: row-reverse;
    }

    span {
        font-size: 10pt;
        font-weight: 500;
        text-align: center;
        white-space: nowrap;
    }

    .text-first {
        margin-right: 3px;
    }
    .text-last {
        margin-left: 3px;
    }
}
