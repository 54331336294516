@import '../../../../variables.scss';

.flank-company-sidebar-links {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: $medium-padding;

    .flank-company-page-links {
        .active {
            color: $flank-green;
        }

        .flank-company-children-links {
            margin-left: $small-margin;
            border-left: 2px solid #e5e7eb;
            max-height: 0;
            overflow: hidden;
            transition: max-height 0.2s ease-in-out;

            .flank-link {
                &.page-tab {
                    white-space: normal !important;
                }

                &.page-tab-child {
                    svg {
                        margin-left: $small-margin;
                    }
                }
            }
        }

        &.open {
            .flank-company-children-links {
                max-height: 1000px;
                overflow: auto;
            }
        }
    }
}
