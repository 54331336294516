@import '../../variables.scss';

.flank-form-group {
    margin-bottom: 1.7em;

    &.chained {
        margin-top: -1.2em;
    }

    &.collected {
        display: flex;
        align-items: stretch;

        & > * {
            margin: 0;
            border-radius: 0 !important;

            &:first-child {
                border-radius: 6px 0 0 $border-radius !important;
            }

            &:last-child {
                border-radius: 0 $border-radius $border-radius 0 !important;
            }
        }
    }
}
