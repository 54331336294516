@import '../../../../variables.scss';

.flank-company-content-section {
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    line-height: $para-line-height;

    .tldr {
        border-radius: $border-radius;
        background-color: $text-background-color;
        padding: 1rem 2rem 1rem 1rem;
        margin: 1.5rem 0 0 0;

        .tldr-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 0.5rem;

            .tldr-text {
                font-size: 20px;
            }
            .importance {
                display: flex;
                gap: $small-gap;

                .arrows {
                    display: flex;
                }
            }
        }
    }
    .paragraph-text {
        padding-top: 1.5rem;
    }
    .paragraph-text p {
        padding-top: 0;
        margin-top: 0;
    }

    &.title-only {
        padding: 0;
        margin: 0;
    }
}
