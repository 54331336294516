@import '../../../../variables.scss';

.flank-company-page-navigation-buttons {
    display: flex;
    justify-content: space-between;

    &.right {
        justify-content: flex-end;
    }

    .flank-page-navigation-button {
        display: flex;
        flex-direction: column;
        gap: 6px;
        padding: $medium-padding $large-padding;
        border-radius: $medium-border-radius;
        background-color: $off-white-background-color;
        color: $black-color;
        text-decoration: none;
        transition: background-color 0.2s ease-in-out;
        max-width: 45%;

        &:hover {
            background-color: darken($off-white-background-color, 5%);
        }

        .direction {
            font-weight: bold;
            font-size: 12px;
            text-transform: uppercase;

            &.previous {
                text-align: right;
            }
        }

        .page-title {
            display: flex;
            align-items: center;
            color: $flank-green;
            text-transform: uppercase;
            gap: 6px;
            font-weight: bold;
        }
    }
}
