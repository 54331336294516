@import './variables.scss';

@font-face {
    font-family: 'Roboto';
    src: url('../public/assets/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
    font-weight: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('../public/assets/fonts/Roboto/Roboto-Bold.ttf') format('truetype');
    font-weight: bold;
}

@font-face {
    font-family: 'Roboto';
    src: url('../public/assets/fonts/Roboto/Roboto-Italic.ttf') format('truetype');
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    src: url('../public/assets/fonts/Roboto/Roboto-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

* {
    box-sizing: border-box;
}

html,
body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    max-width: 100%;
}

html {
    height: 100%;
}

body {
    font-family: $font-family;
    font-size: $base-font-size;
    min-height: 100%;
    height: 100%;

    #root {
        height: 100%;
    }
}
