@import '../../../../../variables.scss';

.company-quick-look {
    margin-bottom: 1em;

    .company-quick-look-data {
        display: flex;

        & > div {
            margin-right: 1em;
        }

        .market-cap {
            font-size: 20pt;
        }

        .change {
            .change-value {
                transition: color ease-in-out 0.1s;
            }

            &.down {
                .change-value {
                    color: #a50f0d;
                }
            }

            &.up {
                .change-value {
                    color: #1f793c;
                }
            }

            .change-time {
                color: #717479;
                font-size: 10pt;
            }
        }
    }

    .company-quick-look-update {
        margin: 0.5em 0;
        color: #717479;
        font-size: 11pt;
    }
}
