@import '../../../../variables.scss';

.flank-form-checkbox-label {
    display: flex;
    align-items: center;
    .flank-form-control-checkbox {
        appearance: none;
        background-color: #fff;
        margin: 0;
        font: inherit;
        color: currentColor;
        width: 1em;
        height: 1em;
        border: 1px solid $flank-green;
        border-radius: $border-radius;
        transform: translateY(-0.075em);
        display: inline-grid;
        place-content: center;
        margin-right: 0.25em;
        margin-top: 0.3em;

        &:checked {
            background-color: $flank-green;
        }

        &:checked::before {
            content: '';
            border: 0 solid white;
            border-width: 0 3px 3px 0;
            transform: rotate(45deg);
            width: 4px;
            height: 9px;
            margin-top: -2px;
        }
    }
}
