.btns {
    display: flex;
    width: 146px;
    height: 36px;
    justify-content: flex-end;
}

.view-posts-button {
    border: none;
    background: none;
    width: 22px;
    height: 22px;
    padding-right: 40px;
    padding-bottom: 7px;
    padding-top: 7px;
    align-items: center;
    cursor: pointer;
    justify-content: flex-end;
}
