@import '../../../variables.scss';

.grid-layout-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 10px;

    .badge-container {
        width: 110px;
    }
}

@media (max-width: 600px) {
    .grid-layout-container {
        grid-template-columns: 1fr 1fr;
    }
}
