@import '../../variables.scss';

.search-result-row-container {
    display: flex;
    padding: 6px;
    column-gap: 10px;

    #company-logo {
        width: 56px;
        height: 56px;
        border-radius: 3px;
        object-fit: cover;
    }
    .company-details-container {
        row-gap: 6px;
        display: flex;
        flex-direction: column;
        .header {
            display: flex;
            flex-direction: column;
            .company {
                &-symbol {
                    font-size: 10pt;
                    color: $flank-green;
                    text-transform: lowercase;
                }
                &-name {
                    font-size: 16pt;
                }
            }
        }

        .bricks-row {
            display: flex;
            column-gap: 6px;
            flex-wrap: wrap;
            row-gap: 6px;

            .percentage-change-range {
                padding: 4px;
                border-radius: 4px;
                font-size: 10pt;
                min-width: unset;
            }
        }
    }
}
