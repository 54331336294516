@import '../../variables.scss';

.alpha-template {
    min-height: 100vh;
    overflow: hidden;
}

.alpha-wrapper {
    background-color: $flank-green-bg-light;
    width: 100%;
    padding-top: 10rem;
    min-height: 100vh;
    flex-direction: column;
    display: flex;

    .logo-wrapper {
        text-align: center;
        .logo {
            width: 150px;
            margin: auto;
            .leaf {
                fill: $flank-green;
            }
        }
    }
}

.content-body {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 70vw;
    margin: 0 auto;

    .left {
        margin: 1.25rem 0;

        .main-text {
            line-height: 2.5rem;
            font-size: xx-large;
            font-weight: 500;
        }
        .sub-text {
            font-size: large;
        }

        .login {
            margin-top: 0.5rem;
            font-size: $small-font-size;

            .flank-link {
                font-size: $small-font-size;
            }
        }
    }

    .right {
        display: flex;
        align-items: center;
        width: auto;
    }
}

@media (max-width: $phone-breakpoint) {
    .logo-wrapper {
        .logo {
            max-width: 100px;
        }
    }

    .content-body {
        flex-direction: column-reverse;
        margin: auto;
        max-width: 90vw;

        .left {
            .main-text {
                font-size: x-large;
                font-weight: 500;
            }
            .sub-text {
                font-size: medium;
            }
        }

        .right {
            width: 90%;

            & > * {
                width: 100%;
            }
        }
    }

    .rushmore {
        max-width: 100vw;
        margin-top: 2rem;
    }

    .alpha-wrapper {
        padding-top: 5rem;
    }
}
