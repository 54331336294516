@import '../../variables.scss';

.flank-filter-panel {
    margin: 0 1em;
    padding: 2rem 1rem !important;

    .flank-filter-panel-header {
        font-size: 18pt;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

@media (max-width: $phone-breakpoint) {
}
