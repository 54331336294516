@import '../../variables.scss';

.save-filters-container {
    animation: fadeInAnimation 0.2s ease-in-out;
    .input-row {
        display: flex;
        align-items: center;
        column-gap: 5px;

        .text-input {
            padding: 6px;
            border-radius: 4px;
            border: 1px solid $flank-gray-bg;
            flex: 1;
            font-size: 12pt;
            font-weight: 500;

            &::placeholder {
                font-size: 10pt;
            }

            &:focus {
                outline: none;
                border-color: $flank-green;
            }
        }
    }
}
