@import '../../variables.scss';

.flank-page-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .page-top {
        padding-bottom: 2rem;
    }

    .flank-page-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex: 1;

        .flank-page-header-content {
            display: flex;
            align-items: center;

            .image {
                height: 4rem;
                margin-right: 1rem;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    max-height: 100%;
                }
            }
            .ticker {
                color: $flank-green;
            }

            .flank-page-header-text {
                flex: 1;

                a {
                    text-transform: lowercase;
                    display: block;
                }

                h1 {
                    font-size: $title-font-size;
                    font-weight: bold;
                    text-transform: capitalize;
                    margin: 0;
                    padding: 0;
                    display: flex;
                    align-items: center;

                    .edit-icon {
                        font-size: $title-font-size;
                        padding: 0 0.25em;
                    }
                }
            }
        }
        &.title-only {
            padding: 0;
        }

        .flank-page-header-actions {
        }
    }
}

@media (max-width: $phone-breakpoint) {
    .flank-page-header {
        .image {
            height: 3rem;
            margin-right: 1rem;

            img {
                height: 100%;
            }
        }
        margin-bottom: 1.5rem;

        .flank-page-header-actions {
            .open-side-panel {
                display: block;
                font-size: 14pt;
                padding: 0.25rem 0.75rem;
                background-color: #f6f7f9;
                border: none;
                border-radius: $border-radius;
                white-space: nowrap;
                color: #000;

                svg {
                    margin: 2px -7px;
                }

                &:hover {
                    background-color: darken(#f6f7f9, 5%);
                }
            }
        }
    }
}
