@import '../../variables.scss';

.my-saved-filters-container {
    animation: fadeInAnimation 0.2s ease-in-out;
    .filter-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        span {
            font-size: 12px;
            font-weight: 500;
            padding: 10px 0;
        }
        .buttons-container {
            display: flex;
            column-gap: 24px;
            padding: 10px 12px;

            .button {
                cursor: pointer;
            }

            .delete-btn {
                color: $danger-color;
            }
        }
    }
}
