@import '../../variables.scss';

.user-settings-wrapper {
    display: flex;
    flex-direction: column;
    margin: 1rem 0 8rem;
}

.account-preferences {
    padding: 2rem 0 1rem 0;

    .flank-spacer {
        margin: 6px 0;
    }

    .delete-account {
        padding-top: 40px;

        .flank-spacer {
            margin: 6px 0;
        }
    }
}

.modal {
    display: flex;
    background-color: black;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    min-height: 100vh;
    width: 100vw;
    background-color: rgb(0, 0, 0);
    background-color: rgb(0, 0, 0, 0.6);
    z-index: 100;
}

.modal-content {
    background-color: white;
    box-sizing: border-box;
    max-width: 432px;
    width: 80%;
    padding: 1.5rem 2rem;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    .x-button-wrapper {
        position: absolute;
        right: 12px;
        top: 12px;
        border: none;
        padding: 0;
        margin: 0;
    }

    .title-wrapper {
        display: flex;
        align-items: center;
    }

    .icon-wrapper {
        font-size: 2.25rem;
        height: 2.25rem;
    }

    .modal-title {
        margin-left: 0.5rem;
        font-size: 1.5rem;
    }

    .modal-description {
        margin: 1.25rem 0;
    }
    .alert {
        margin: 1.25rem 0;
    }
}

.account-preference {
    display: flex;
    margin: 0.75rem 0;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    .account-preference-title {
        line-height: 1.25rem;
        margin: 0 0 0.25rem 0;
    }

    .account-preference-tag {
        color: #7c7c7c;
        margin: 0 0 0.25rem 0;
    }
}
.delete-spacer {
    margin-top: 2rem;
}

.logout-link {
    border-radius: 4px;
    background-color: white;
    display: flex;
    align-items: flex-end;
    margin-left: 445px;
    width: 200px;
    overflow-x: hidden;
    overflow-y: hidden;
    margin-bottom: 1rem;
    margin-top: 2rem;
}
