@import './../../variables.scss';

.codex-editor__redactor {
    padding-bottom: 20px !important;
    // padding-top: 10px !important;
}

// it is incredibly hard to style the editorjs component. Try at your own peril.

.editorjs {
    .ce-toolbar {
        background-color: $input-background-color;
        border: 1px solid $input-border-color;
        border-radius: $border-radius;
    }
}
