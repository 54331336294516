@import '../../variables.scss';
.flank-line-chart {
    border-top: 1px $gray-color solid;
    border-bottom: 1px $gray-color solid;
}
.barchart-container {
    position: relative;
    margin: auto;
    width: 100%;
    padding-left: 10%;
    padding-right: 5%;
    border-top: 1px $gray-color solid;
    border-bottom: 1px $gray-color solid;
    border-radius: 0;
}

@media (max-width: $phone-breakpoint) {
}
