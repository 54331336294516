@import '../../variables.scss';

.research-guide-headline {
    border-radius: $border-radius;
    background-color: $text-background-color;
    padding: 1rem 1rem 1rem 1rem;

    .step-number {
        color: $flank-green;
        font-size: $small-font-size;
        padding-bottom: 0.25rem;
        span {
            font-size: $small-font-size;
            color: $flank-green;
        }
    }
}
