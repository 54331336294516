@import '../../../../variables.scss';
.thesis-form {
}

.thesis-stats {
    display: flex;
    gap: 12px;
}

.thesis-body {
    padding: 2rem 0;
}
.thesis-text-input {
    padding: 2rem 0;
}
.thesis-bullet {
    .bullet-header {
        font-weight: 600;
        padding-top: 1rem;
        padding-bottom: 1.5rem;
    }

    .bullet-body {
        .option-container {
            position: relative;
            padding-bottom: 0.5rem;
            margin-bottom: 1rem;
        }

        .bullet-subtext {
            position: absolute;
            left: 0;
            top: 50%;
            margin-top: 0.5rem;
            color: $flank-gray-text;
            font-size: 10pt;
        }

        label {
            display: flex;
            align-items: center;
            margin-bottom: 1rem;
            &:hover input[type='radio'] + span {
                transform: scale(1.15);
                transition: transform 0.2s ease-in-out;
            }
        }

        input[type='radio'] {
            position: absolute;
            opacity: 0;
            width: 0;
            height: 0;

            &:checked + span {
                border-color: $flank-green;

                &::after {
                    content: '';
                    display: block;
                    height: 14px;
                    width: 14px;
                    background-color: $flank-green;
                    border-radius: 50%;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }

        span {
            display: inline-block;
            position: relative;
            height: 22px;
            width: 22px;
            margin-right: 1rem;
            background-color: transparent;
            border: 1px solid black;
            border-radius: 50%;
        }
    }

    &:not(:first-child) {
        padding-top: 1rem;
    }
}

.submit-btn {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    padding: 3rem 0rem;
}

label:hover input[type='radio'] + span {
    background-color: $light-gray-color;
}

label:hover input[type='radio']:checked + span::after {
    background-color: $flank-green;
}
