@import '../../variables.scss';

.waves {
    bottom: 0;
    left: 0;
    width: 100%;
    transform: rotate(180deg);
    opacity: 0.5;
    flex-grow: 1;
    padding-bottom: 5rem;
}

.waves svg {
    display: table;
    position: relative;
    max-height: 80px;
    width: calc(113% + 1.3px);
    margin: auto;
}

.waves .shape-fill {
    fill: #00cd00;
}
