@import '../../variables.scss';

.guided-research {
    padding: 2rem 0;

    p {
        line-height: $para-line-height;
    }

    .forward-btn {
        display: flex;
        justify-content: flex-end;
        padding: 2rem 0;
    }
    .section-title {
        padding-top: 2rem;
    }
    .sector-selector {
        display: flex;
        justify-content: center;
    }
    .sector {
        width: 112px;
        height: 128px;
        border: 1px solid #e5e5e5;
        text-align: center;
        padding-top: 3rem;
    }

    .individual-sector {
        padding: 16px 24px;

        .company-size-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
    .metric {
        padding: 1rem;
        border: 1px solid #e5e5e5;
    }
}

.flank-button.research-btn.action {
    padding: 1rem;
}

.button-icon-only {
    background: transparent;
}

@media only screen and (max-width: $phone-breakpoint) {
    .guided-research {
        padding: 0;
    }
}
