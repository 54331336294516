@import '../../variables.scss';

.search-result {
    padding: $medium-padding;
}

.search-container {
    display: flex;
    margin: 32px 0 42px;

    .search-form {
        flex: 1;
    }

    .filters-btn {
        padding: 0.6rem;
        margin-left: 1rem;
        font-size: 12pt;

        .icon {
            font-size: 14pt;
        }
    }
}

.search-modal {
    &-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: black;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        min-height: 100vh;
        width: 100vw;
        background-color: rgb(0, 0, 0, 0.6);
        z-index: 2;
    }
    &-content {
        background-color: white;
        width: 624px;
        position: relative;
        overflow-y: auto;
        animation: slideUpAnimation 0.2s ease-in-out;
        margin: 5rem 0;
    }
    &-header {
        text-align: center;
        padding: 24px;
        border-bottom: 1px solid #e5e7eb;

        &-x-container {
            position: absolute;
            top: 1.5rem;
            left: 1.5rem;
        }

        &-title {
            margin: 0;
            font-size: 1rem;
        }
    }
    &-body {
        display: flex;
        flex-direction: column;

        section {
            padding: 2rem 1.5rem;
            border-bottom: 1px solid #e5e7eb;

            .company-size-header {
                display: flex;
                align-items: center;
                justify-content: space-between;

                #company-size-category {
                    padding: 6px 12px;
                    border-radius: 12rem;
                    background: #e5e7eb;
                    border: none;

                    button {
                        color: black;
                        font-size: 0.75rem;
                    }
                }
            }

            h3 {
                font-size: 1rem;
                margin: 0;
                text-transform: uppercase;
            }

            p {
                font-size: 14px;
                span {
                    color: $flank-green;
                    font-weight: bold;
                }

                #sorting-direction {
                    color: black;
                    text-decoration: underline;
                }
            }
        }
    }
    &-footer {
        display: flex;
        flex-direction: column;
        position: sticky;
        bottom: 0;
        background-color: #fff;

        .buttons-container {
            display: flex;
            padding: 16px 24px;
            align-items: center;
            justify-content: space-between;

            .save-filters-container {
                display: flex;
                column-gap: 12px;
            }
        }

        .search-btn {
            padding: 6px 12px;
        }

        .my-filters-container {
            padding: 0 24px 24px;
        }
    }
}

.search-results-container {
    display: flex;
    flex-direction: column;
    margin-top: 20px;

    .search-result-wrapper {
        margin: 20px 0;
    }
}

.search-results-load-more {
    display: flex;
    justify-content: center;
}

// For mobile devices
@media (max-width: 767px) {
    .search-modal {
        &-content {
            width: 100%;
            height: 100%;
            padding-bottom: 3rem;
        }
        &-footer {
            .buttons-container {
                padding: 16px 8px;

                .save-filters-container {
                    column-gap: 4px;
                }
            }
        }
    }
}

@keyframes slideUpAnimation {
    from {
        transform: translateY(1000px);
    }
}
