.post-title-input,
.post-subtitle-input {
    border: none; // Removes borders
    margin: 0; // Resets margin
    padding: 0; // Resets padding

    &::placeholder {
        text-transform: capitalize;
        font-weight: bold;
    }
}

.reference-selector {
    margin-top: -10px; // Adjusts the space above the selector. Adjust the value as needed.
    margin-bottom: 20px;
}

.post-button-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    gap: 10px;
}

.rich-text-editor-container {
    border-bottom: 1px solid gray;
}

.post-title-input {
    font-size: 1.5em;
    font-weight: bold;
    margin-top: 30px;
    &::placeholder {
        color: black;
        font-size: 1em;
    }
}

.post-subtitle-input {
    margin-top: -20px; // Adjust this value to reduce space between inputs
    font-size: 1.25em;
    color: gray;
    &::placeholder {
        color: gray;
        font-size: 1em;
    }
}
