@import '../../variables.scss';

.search-result-brick-container {
    border-radius: 3px;
    padding: 3px 6px;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    background: $gray-color;

    &.inline {
        flex-direction: row;
        column-gap: 6px;
    }
    .title {
        color: $dark-gray-color;
        font-size: 10pt;
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
    }
    .value {
        font-size: 10pt;
        color: $dark-gray-color;
    }

    .red {
        color: $negative-value-color;
    }
    .green {
        color: $positive-value-color;
    }
}
