@import '../../variables.scss';

$base-page-width: 656px;
$base-sidebar-width: 352px;
$medium-page-width: 656px;
$medium-sidebar-width: 352px;

.flank-page {
    flex: 1;
    margin: 0 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: $xlarge-gap;
    width: calc($base-page-width + $base-sidebar-width + $xxlarge-gap);
    max-width: 100%;
    height: 100%;

    &.no-pad {
        padding: 0em;
    }

    &.full {
        width: 100%;
    }

    &.thinner {
        max-width: 40rem;
    }

    .flank-page-main-content {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        padding: $medium-padding;
        width: $base-page-width;
        border-right: 1px solid $gray-color;
        min-height: 100%;

        & .full-content-width {
            margin-left: -$medium-padding;
            margin-right: -$medium-padding;
        }

        .content-wrapper {
            flex-grow: 1;
            min-height: 100vh;
            padding-bottom: 150px;
        }

        .footer {
            margin-bottom: -$large-padding;
            margin-top: $large-margin;
        }
    }

    .flank-page-side-content {
        padding: $small-padding 0;
        width: $base-sidebar-width;
        position: sticky;
        top: 0;
        max-height: 100vh;
        overflow-y: auto;
        margin-right: 16px;

        .sidebar-content {
            background-color: #fff;
            display: flex;
            flex-direction: column;
            gap: $large-gap;

            & > * {
                border-radius: $large-border-radius;
                padding: $medium-padding 0;
                background-color: $off-white-background-color;
                margin-right: $small-margin;
            }
        }

        .sidebar-actions {
            .close-side-panel {
                display: none;
            }
        }
    }
}

@media (max-width: $desktop-breakpoint) {
    .flank-page {
        width: 100%;
        max-width: 100%;
        gap: $medium-gap;
        display: flex;

        .flank-page-main-content {
            width: $medium-page-width;
        }

        &.with-sidebar {
            .flank-page-main-content {
                width: 100%;
                padding-right: 0em;
            }
        }

        &.sidebar-open {
            width: calc($medium-page-width + $medium-sidebar-width + $medium-gap);
            .flank-page-main-content {
                width: calc($medium-page-width - $medium-sidebar-width - $medium-gap);
            }
        }

        .flank-page-side-content {
            &.open {
                display: flex;
                justify-content: center;
                flex-direction: column;
                width: $medium-sidebar-width;
                min-width: $medium-sidebar-width;
                max-width: $medium-sidebar-width;
            }

            .sidebar-content {
                box-shadow: none;
                overflow: auto;
                border-radius: 0;
                min-height: 100vh;
            }
        }
    }
}

@media (max-width: $phone-breakpoint) {
    .flank-page {
        flex-grow: 1;
        width: 100%;

        .flank-page-main-content {
            width: 100%;
            background-color: #fff;
        }

        &.sidebar-open {
            width: 100%;
        }

        .flank-page-side-content {
            overflow: hidden;
            white-space: nowrap;
            position: fixed;
            top: 0;
            right: 0;
            width: 100%;
            min-width: 100%;
            max-width: 100%;
            margin: 0;
            padding: 0;
            display: flex;
            justify-content: center;
            flex-direction: column;
            height: 100vh;
            transform: translateX(100%);
            transition: transform $transition-speed-obj-moving $transition-shape;
            z-index: 2;

            &.open {
                transform: translateX(0);
                min-width: 100%;
                z-index: 2;
            }

            .sidebar-content {
                box-shadow: none;
                overflow: auto;
                padding: 0 0 2rem 0;
                height: 100vh;
            }

            .sidebar-actions {
                display: flex;
                justify-content: flex-end;

                .close-side-panel {
                    display: none;
                    flex-shrink: 1;
                    font-size: 20pt;
                    border: none;
                    padding: 0.5em;
                    background-color: transparent;
                    color: #000;
                }
            }
        }
    }
}
