@import '../../../../variables.scss';

a.flank-navigation-link {
    width: 100%;
    display: flex;
    flex-direction: row;
    font-size: 16px;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
    color: $black-color;
    text-decoration: none;
    padding: 8px 16px 8px 16px;
    margin-left: -$small-margin;
    border-radius: 13px;
    white-space: nowrap;
    transition: $hover-background;
    position: relative;
    right: -$xsmall-margin;

    &.disabled {
        color: $disabled;
        opacity: 0.5;
        svg {
            stroke: $disabled;
        }

        &:hover {
            cursor: not-allowed;
        }
    }

    svg {
        padding: 0;
        margin: 0;
        width: 24px;
    }

    &:hover {
        background-color: #f6f7f9;
    }

    & > span {
        font-size: 16px;
    }

    &.active {
        font-weight: 600;
    }

    &.action {
        white-space: nowrap;
        border-radius: $xlarge-border-radius;
        padding: $medium-padding 0;
        background-color: $flank-green;

        border: 1px solid $flank-green-bg;
        background-color: $flank-green-bg;
        color: #fff;
        font-weight: bold;
        text-align: center;

        :not(span) {
            display: none;
        }

        span {
            display: block;
            width: 100%;
        }

        &:hover {
            background-color: $flank-green-bg-hover;
        }

        &:active {
            background-color: $flank-green-bg-active;
        }
    }
}
