@import '../../../../variables.scss';

.content-section {
    padding-top: 1rem;
    margin-top: 2rem;
}

.leader-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .leader-card {
        padding: 1rem 0;
        font-size: $base-font-size;
        font-weight: 500;
    }

    .leader-name {
        font-size: $base-font-size;
        font-weight: bold;
        padding-bottom: 0.5rem;
    }

    .leader-text {
        font-size: $base-font-size;
    }
}
