@import '../../variables.scss';

.section-header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $small-padding;
}

.section-header-text {
    @include title-font;
}

.section-header-icon {
    padding: 0 0.25em;
    cursor: pointer; // Makes the icon visually clickable
    transition: transform $transition-speed-fast $transition-shape; // Smooth transition for icon click

    &:hover {
        transform: scale(1.1); // Slightly enlarges the icon on hover for a clickable effect
    }

    &:active {
        transform: scale(0.9); // Slightly shrinks the icon on click for a clickable effect
    }
}
