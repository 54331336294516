@import '../../../../../variables.scss';

.company-data-table {
    display: flex;
    flex-direction: column;

    .row {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 1em;

        .label {
            flex: 1;
        }

        .value {
            min-width: 5rem;
            text-align: right;
        }
        span {
            font-weight: bold;
        }

        .multi-value {
            .row {
                border-bottom: none;
                padding: 0.25em 0;

                .label {
                    margin-right: 3em;
                }
            }
        }
    }
}
