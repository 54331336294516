@import '../../variables.scss';

.flank-form-group {
    position: relative;

    &:last-child {
        margin-bottom: 0;
    }
}

.flank-form-label {
    display: block;
    font-size: 12pt;
    font-weight: bold !important;
    font-family: $font-family;
    padding: 1rem 0;
}

.flank-form-control {
    display: block;
    width: 100%;
    padding: 0.5rem 0.75rem;
    font-size: $base-font-size;
    font-family: $font-family;
    line-height: 1.5;
    color: $text-color;
    background-color: $input-background-color;
    background-clip: padding-box;
    border: 1px solid $input-border-color;
    border-radius: $border-radius;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &::placeholder {
        font-size: 10pt;
    }

    &:focus {
        outline: none;
        border-color: $flank-green;
    }

    &:disabled {
        background-color: #ddd;
        color: #666;
        cursor: not-allowed;

        &:hover {
            border-color: #ddd;
        }
    }
}

.flank-form-control-group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;

    & > * {
        border-radius: 0 !important;
    }

    & > :not(:first-child) {
        border-left: none !important;
    }

    & > :first-child {
        border-radius: $border-radius 0 0 $border-radius !important;
    }

    & > :last-child {
        border-radius: 0 $border-radius $border-radius 0 !important;
    }

    &.seamless {
        & > :not(:last-child) {
            border-right: none !important;
        }
    }

    .flank-form-control {
        flex: 1 1 auto;
    }

    .flank-form-control:last-child {
        margin-right: 0;
    }
}

.flank-typeahead {
    position: absolute;
    top: 100%;
    width: 100%;
    border: 1px solid #000;
    border-radius: $border-radius;
    box-shadow: 0 0.25em 0.25em rgba($flank-green, 0.25);
    transition: box-shadow 0.1s ease-in-out, margin-top 0.1s ease-in-out;
    z-index: 100;
    background-color: #fff;
    color: #000;

    &.off-screen {
        top: inherit;
        bottom: 100%;
        box-shadow: 0 -0.25em 0.25em rgba(0, 205, 0, 0.25);
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            display: block;

            &:hover,
            &.active {
                background-color: lighten($flank-green, 40%);
            }

            button {
                display: block;
                width: 100%;
                padding: 1em;
                margin: 0;
                border: none;
                background-color: transparent;
                color: $text-color;
                font-size: $base-font-size;
                font-family: $font-family;
                line-height: 1.5;
                text-align: left;
                cursor: pointer;
            }
        }
    }
}

.button-text {
    display: flex;
    border: none;
    text-decoration: none;
    width: 100%;
    font-size: 12pt;
    white-space: nowrap;

    &:hover {
        color: $flank-green;
    }

    .company-symbol {
        color: $flank-green;
        padding: 0 1rem 0 0;
        font-weight: 600;
    }

    .company-name {
    }
}
