@import '../../../variables.scss';

$green: $flank-green;
$gray: #bbbbbb;

.research-btn {
    outline: none;
    height: 36px;
    text-align: center;
    width: 100px;
    border-radius: $border-radius;
    background-color: $flank-green-bg;
    border: none;
    color: white;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);

    &:after {
        content: 'Research';
    }

    &:hover {
        background-color: $flank-green-bg-hover;
    }

    &.research-status {
        width: 36px;
        border-radius: 36px;
        border-width: 0px;
        background: transparent;
        position: relative;

        .progress-bar.active {
            opacity: 1;
            transition: opacity 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
        }

        &:after {
            content: '';
        }

        &:hover {
            color: $green;
            background: white;
        }
    }

    .progress-bar {
        top: 0px;
        left: 0px;
        transition: opacity 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.35s;
        width: 36px;
        height: 36px;
        opacity: 0;
        border-radius: 50%;
        position: absolute;
        overflow: visible;
        color: black;
        background: radial-gradient(closest-side, white 79%, transparent 80% 100%),
            conic-gradient(#00cd00 calc(var(--progress-value) * 1%), #eff3f4 0);

        &::before {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            counter-reset: percentage var(--progress-value);
            content: counter(percentage) '%';
            animation: progress 2s forwards;
        }
    }
}
