@import '../../../../variables.scss';

.flank-navigation {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    padding: 8px 12px;
    gap: $medium-gap;

    .large-screen-nav {
        display: flex;
        flex-direction: column;
        flex: 1;
    }

    .small-screen-nav {
        display: none;
    }

    hr {
        width: calc(100% + #{$medium-padding * 2});
        border: 0;
        border-top: 1px solid $gray-color;
        margin-left: -$medium-padding;
        margin-right: -$medium-padding;
    }

    .flank-navigation-logo {
        position: relative;
        width: 25px;
        height: 25px;
        margin: $small-margin;
        margin-bottom: $large-padding;

        .logo-img {
            width: 100%;
        }

        .logo {
            fill: black;
            height: 25px;
            width: 77px;
            position: absolute;
            top: 0;
            left: 0px;

            .leaf {
                fill: $flank-green;
                transition: fill 0.05s ease-in-out;
            }

            &:hover {
                .leaf {
                    fill: $flank-green;
                }
            }

            g {
                display: none;
            }
        }
    }

    .flank-navigation-links {
        display: flex;
        flex-direction: column;
        gap: 16px;

        & > a.flank-navigation-link {
            &.sidebar-toggle {
                display: none;
            }
        }
    }

    .flank-navigation-spacer {
        flex: 1;
    }

    .flank-navigation-user-menu {
        position: relative;

        hr {
            margin: 0;
            padding: 0;
        }

        button {
            width: 100%;
            display: flex;
            align-items: center;
            background-color: transparent;
            border: none;
            font-size: $large-font-size;
            gap: $medium-gap;
            padding: $medium-padding;
            border-radius: $xlarge-border-radius;
            transition: $hover-background;

            svg {
                transition: transform $transition-speed-fast $transition-shape;
            }

            &:hover {
                background-color: $light-gray-color;
                cursor: pointer;

                svg {
                    transform: scale(1.1);
                }
            }
        }

        .flank-navigation-links {
            @include grow-height-in-hidden;
        }

        &.open {
            button {
                background-color: $light-gray-color;
            }

            .flank-navigation-links {
                @include grow-height-in-visible;
                display: flex;
                position: absolute;
                bottom: 100%;
                left: 0;
                margin-bottom: $small-margin;
                min-width: $width-full;
                border-radius: $small-border-radius;
                background-color: $light-gray-color;
                padding: $medium-padding 0;

                & > a.flank-navigation-link {
                    display: inline-flex;
                    font-size: $regular-font-size;
                    border-radius: $small-border-radius;
                    padding: $small-padding $medium-padding;

                    &:hover {
                        background-color: $gray-color;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: $desktop-breakpoint) {
    a.flank-navigation-link {
        justify-content: center;
        border-radius: $medium-border-radius;
        padding: $small-padding 0;
        right: 0;
        margin-left: 0;

        span {
            display: none;
        }

        &.sidebar-toggle {
            display: inline-block;
        }

        &.action {
            padding: $small-padding 0;
            height: 50px;
            width: 50px;

            :not(span) {
                display: block;
                margin-left: -0.5px;
                padding-left: 0;
            }

            span {
                display: none;
            }
        }
    }

    .flank-navigation-user-menu {
        position: relative;

        button {
            justify-content: center;
            border-radius: $medium-border-radius;
            padding: $xsmall-padding;

            span {
                display: none;
            }
        }

        .flank-navigation-links {
            & > a.flank-navigation-link {
                justify-content: flex-start;
            }
        }
    }
}

@media only screen and (max-width: $phone-breakpoint) {
    .flank-navigation {
        max-width: 100vw;
        padding: 0;

        .flank-navigation-logo {
            display: none;
        }

        .large-screen-nav {
            display: none;
        }

        .small-screen-nav {
            display: flex;
            max-width: 100%;

            .flank-navigation-links {
                width: 100%;
                max-width: 100vw;
                flex-direction: row;
                gap: none;
                justify-content: space-around;

                a.mobile-navigation-link {
                    padding: $xmedium-padding 0;
                    position: relative;

                    span {
                        display: none;
                    }

                    svg {
                        max-height: 22px;
                    }

                    &.disabled {
                        color: $disabled;
                        opacity: 0.5;
                        svg {
                            stroke: $disabled;
                        }

                        &:hover {
                            cursor: not-allowed;
                        }
                    }

                    .guided-research {
                        ::before {
                            content: '';
                            position: absolute;
                            z-index: -1;
                            top: 4px;
                            left: -8px;
                            right: -8px;
                            bottom: 6px;
                            background-color: $flank-green;
                            border-radius: $xlarge-border-radius;
                        }
                    }
                }
            }
        }
    }
}
