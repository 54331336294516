@import '../../variables.scss';

.flank-admin-blocks {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: flex-start;
    margin: 0 -1em;

    .flank-admin-block {
        width: calc(33.33% - 2em);
        margin: 1em;
        border: 1px solid #000;
        border-radius: $border-radius;
        padding: 1em;
        display: flex;
        align-items: center;
        text-decoration: none;
        box-shadow: 0 0.25em 0.25em rgba($flank-green, 0.25);
        transition: box-shadow 0.1s ease-in-out, margin-top 0.1s ease-in-out;
        justify-content: flex-start;

        &:hover {
            background-color: #efefef;
            color: #fff;
            margin-top: 0.75em;
            box-shadow: 0 0.5em 0.5em rgba($flank-green, 0.25);
        }

        .flank-admin-block-icon {
            font-size: 20pt;
            line-height: 15pt;
            color: $flank-green;
        }

        .flank-admin-block-title {
            margin-left: 1em;
            font-size: 14pt;
            color: #000;
        }
    }
}

@media (max-width: $phone-breakpoint) {
    .flank-admin-blocks {
        flex-direction: column;
        align-items: flex-start;
        margin: 0;
        padding: 1em 0;

        .flank-admin-block {
            width: 100%;
            margin: 0;
            margin-bottom: 1em;

            &:hover {
                background-color: #efefef;
                color: #fff;
                margin-top: 0;
            }
        }
    }
}
