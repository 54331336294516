@import '../../../../variables.scss';

.cover-image-wrapper {
    width: $width-full;
    position: relative;
    overflow: hidden;

    &:before {
        content: '';
        display: block;
        padding-top: 33.33%; // maintain 3:1 aspect ratio
    }
}

.cover-image-upload {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    cursor: pointer;
    background-color: #f0f0f0;
    text-align: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: $small-border-radius;
    border: 2px dashed lightgray;

    input[type='file'] {
        margin: $small-margin;
        padding: $small-padding;
    }

    .placeholder {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;

        .placeholder-main-text {
            font-size: $regular-font-size;
            color: $text-color;
            margin-bottom: $small-gap;
            font-weight: 600;
        }

        .placeholder-sub-text {
            font-size: $small-font-size;
            color: $gray-text-color;
        }
    }

    .hidden {
        display: none;
    }
}

.button-container {
    bottom: 10px;
    right: 10px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: flex-end;
}
