@import '../../variables.scss';

.sidebar-content {
    .flank-callback-list {
        padding: 0 1em;
    }
}

.flank-callback-list {
    margin-bottom: 2em;

    .flank-callback-list-header {
        font-size: 18pt;
        margin-bottom: 0.5em;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .flank-form {
        display: flex;
        flex-direction: row;

        .flank-form-group {
            flex-grow: 1;
            padding: 0;
            margin: 0;

            .flank-form-control {
                border-radius: $border-radius 0 0 $border-radius;
            }
        }

        .flank-button {
            appearance: none;
            padding: 0 1em;
            margin: 0;
            border-radius: 0 $border-radius $border-radius 0;
            line-height: 0;
        }
    }
}

@media (max-width: $phone-breakpoint) {
}
