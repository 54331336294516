@import '../../variables.scss';

.flank-base-layout {
    max-width: 1280px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: stretch;

    .flank-base-navigation {
        position: sticky;
        top: 0;
        display: flex;
        flex-direction: column;
        width: 240px;
        min-width: 245px;
        border-right: 1px solid $gray-color;
        height: $height-screen;
        overflow-y: auto;
    }

    .flank-base-content {
        flex: 1;
    }
}

@media only screen and (max-width: $desktop-breakpoint) {
    .flank-base-layout {
        .flank-base-navigation {
            max-width: 72px;
            width: 72px;
            min-width: 72px;
        }
    }
}

@media only screen and (max-width: $phone-breakpoint) {
    .flank-base-layout {
        display: flex;
        flex-direction: column-reverse;
        align-items: stretch;
        width: $width-screen;
        min-height: $height-screen;

        .flank-base-navigation {
            position: sticky;
            bottom: 0;
            display: flex;
            flex-direction: row;
            border: 0;
            border-top: 1px solid $gray-color;
            height: auto;
            padding: 0;
            z-index: 100;
            background-color: $light-gray-color;
            flex-wrap: nowrap;
            width: $width-screen;
            max-width: $width-screen;
            min-width: $width-screen;
        }

        .flank-base-content {
            flex: 1;
            width: $width-screen;
        }
    }
}
