@import '../../variables.scss';

.flank-link {
    color: $flank-green;
    font-family: $font-family;
    font-size: $base-font-size;
    align-items: center;
    justify-content: center;

    &.button {
        display: inline-flex;
        padding: 0.5rem 1rem;
        border: 1px solid $flank-green;
        border-radius: $border-radius;
        text-decoration: none;
        background-color: #fff;
        transition: background-color 0.1s ease-in-out;
        display: flex;
        align-items: center;

        &.active {
            background-color: $flank-green;
            color: #fff;
        }

        &:hover {
            background-color: rgba($flank-green, 0.1);
        }

        &.action {
            border: 1px solid transparent;
            background-color: $flank-green-bg;
            color: #fff;
            text-transform: uppercase;
            font-weight: bold;
            padding: 0.5em 4em;

            &:hover {
                background-color: $flank-green-bg-hover;
            }

            &:active {
                background-color: $flank-green-bg-active;
            }
        }
    }

    &.page-tab {
        text-decoration: none;
        letter-spacing: $tight-font-spacing;
        color: black;
        border: none;
        white-space: nowrap;
        text-transform: uppercase;
        &.active {
            color: $flank-green-bg-hover;
        }

        &:hover {
            color: $flank-green-bg-hover;
        }
    }
    &.page-tab-child {
        //indent it a little bit
        padding-left: 1rem;
        font-size: $small-font-size;
        //12px vertical space between  tabs
        margin: 12px 0;
    }

    &.flank-button {
        color: #000;
        text-decoration: none;
    }

    &.white {
        color: #fff;

        &:hover {
            color: $flank-green;
        }
    }

    &.plain {
        text-decoration: none;
    }

    &.bold {
        font-weight: bold;
    }

    &.block {
        display: block;
        width: 100%;
        text-align: center;
    }

    &.dark {
        border-color: #000;
        color: #000;
        &:hover {
            color: $flank-green;
        }
    }

    &.naked {
        color: inherit;
        background: none;
        border: none;
        text-decoration: none;
        padding: 0;
        font-family: inherit;
        font-size: inherit;
        align-items: inherit;
        justify-content: inherit;
        transition: none;
    }

    .icon {
        line-height: 0;
        display: inline-block;
        margin-right: 0.5em;
        font-size: $base-font-size;

        svg {
            width: 20px;
            height: 20px;
        }
    }

    &.icon-only {
        .icon {
            margin-right: 0;
        }
    }
    &.page-tab {
        display: flex;
        align-items: center;
        justify-content: left;

        svg {
            width: auto;
            height: auto;
        }
    }
}
